import React, { useContext } from "react";
import { useState, useEffect } from "react";
import "../../Tabels/Tabels.css";
import { Link } from "react-router-dom";

import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";

import Offcanvas from "react-bootstrap/Offcanvas";
import Card from "react-bootstrap/Card";
import ModalSave from "../../common/ModelSave";
import Select from "react-select";
import { CancelButton, SaveButton } from "../../common/Button";
import {
  Row,
  Col,
  Form,
  Modal,
  Container,
  InputGroup,
  Button,
} from "react-bootstrap";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import classNames from "classnames";
import { Context } from "../../../utils/context";
import JoditEditor from "jodit-react";
import Table from "react-bootstrap/Table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
library.add(fas);

const EditOffCanvance = (props) => {
  const {
    postData,
    getData,
    Select2Data,
    IMG_URL,
    getDimension,
    deleteData,
    isAllow,
  } = useContext(Context);

  const [social_media, setSocial_media] = useState([]);
  const id = props.show;
  const [data, setData] = useState();
  const [isDisabled, setIsDisabled] = useState(false);

  const [user, setUser] = useState({});
  const GetUserData = async () => {
    const response = await getData(`/usersingleget`);
    if (response?.success) {
      console.log("user :-", response?.data);
      setUser(response?.data);
    }
  };
  useEffect(() => {
    GetUserData();
  }, []);

  const [showModal, setShowModal] = useState({
    code: 0,
    message: "",
  });

  const {
    control,
    register,
    handleSubmit,
    getValues,
    formState: { errors },
    watch,
    setValue,
    reset,
  } = useForm();

  const imageFile = watch("image");
  // console.log("imagesFile:", imagesFile);

  const { fields, append, remove } = useFieldArray({
    control,
    name: "dignitaries_social_media", // Name of your field array
  });

  const [quantityError, setquantityError] = useState("");

  useEffect(() => {
    register("dignitaries_social_media", {
      validate: (value) => {
        const isValid = value && value.length > 0;
        setquantityError(
          isValid ? "" : "At least one dignitaries social media is required"
        );
        return isValid;
      },
    });
  }, [register]);

  const validateSellingPrice = (value, index) => {
    const mrpValue = parseFloat(
      getValues(`dignitaries_social_media.${index}.mrp`)
    );
    const sellingPrice = parseFloat(value);
    if (sellingPrice >= mrpValue) {
      return "Selling price must be less than MRP";
    }
    return true; // Validation passed
  };

  const onSubmit = async (data) => {
    try {
      const DataToSend = new FormData();

      DataToSend.append("name", data?.name);
      DataToSend.append("short_description", data?.short_description);
      DataToSend.append("position", data?.position);

      DataToSend.append("image", data?.image[0]);
      // Append

      const dignitaries_social_media = [];
      data.dignitaries_social_media.forEach((data, index) => {
        dignitaries_social_media.push({
          link: data.link,
          social_media_id: data.social_media_id.value,
        });
      });

      DataToSend.append(
        "dignitaries_social_media",
        JSON.stringify(dignitaries_social_media)
      );
      const response = await postData(
        `/about-us/dignitaries/${id}`,
        DataToSend
      );
      console.log("response", response);
      console.log("data to send", DataToSend);
      if (response?.success) {
        await setShowModal({ code: response.code, message: response.message });
      } else {
        await setShowModal({ code: response.code, message: response.message });
      }
      setTimeout(() => {
        setShowModal(0);
        props.handleClose();
      }, 1000);
    } catch (error) {
      console.log(error);
    }
  };

  const GetAllSocialMedia = async () => {
    {
      const response = await getData("/all-social-media");

      if (response?.success) {
        setSocial_media(await Select2Data(response?.data, "social_media_id"));
      }
    }
  };

  useEffect(() => {
    GetAllSocialMedia();
  }, []);
  const GetEditData = async () => {
    const response = await getData(`/about-us/dignitaries/${id}`);
    reset(response?.data);
  };

  useEffect(() => {
    GetEditData();
  }, []);

  const [imagePreview, setImagePreview] = useState(null); // State to store image preview

  useEffect(() => {
    if (typeof getValues("image") == "string") {
      setImagePreview(IMG_URL + getValues("image")); // Pass the first file in the array
    } else if (imageFile && imageFile?.length > 0) {
      setImagePreview(URL?.createObjectURL(imageFile[0])); // Pass the first file in the array
    }
  }, [imageFile]);

  return (
    <>
      <Offcanvas
        show={props.show}
        style={{ width: "80%" }}
        placement={"end"}
        onHide={props.handleClose}
      >
        <Offcanvas.Header closeButton>
          {/* <Offcanvas.Title>Edit Employee</Offcanvas.Title> */}
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Card className="shadow-lg p-3 mb-5 bg-white rounded">
            <Card.Body>
              <Card.Title>Edit Dignitaries</Card.Title>
              <hr />
              <Container>
                <Form
                  // onSubmit={() => handleSubmit(onSubmit)}
                  role="form"
                  // className="stateclass"
                >
                  <Row>
                    <Col md={3}>
                      <div className="main-form-section mt-3">
                        <Row className="justify-content-center">
                          <Form.Label>Name</Form.Label>
                          <Form.Group>
                            <InputGroup>
                              <Form.Control
                                type="text"
                                name="name"
                                placeholder="Name .."
                                className={classNames("", {
                                  "is-invalid": errors?.name,
                                })}
                                {...register("name", {
                                  required: "Name is required",
                                })}
                              />
                            </InputGroup>
                            {errors.name && (
                              <span className="text-danger">
                                {errors.name.message}
                              </span>
                            )}
                          </Form.Group>
                        </Row>
                      </div>
                    </Col>
                    <Col md={3}>
                      <div className="main-form-section mt-3">
                        <Row className="justify-content-center">
                          <Form.Label>Position</Form.Label>
                          <Form.Group>
                            <InputGroup>
                              <Form.Control
                                type="text"
                                name="position"
                                placeholder="Position.."
                                className={classNames("", {
                                  "is-invalid": errors?.position,
                                })}
                                {...register("position", {
                                  required: "Position is required",
                                })}
                              />
                            </InputGroup>
                            {errors.position && (
                              <span className="text-danger">
                                {errors.position.message}
                              </span>
                            )}
                          </Form.Group>
                        </Row>
                      </div>
                    </Col>
                    <Col md={3}>
                      <div className="main-form-section mt-3">
                        <Row className="justify-content-center">
                          <Form.Label>Short Description</Form.Label>
                          <Form.Group>
                            <InputGroup>
                              <Form.Control
                                type="text"
                                name="short_description"
                                placeholder="Short Description"
                                maxLength={40}
                                className={classNames("", {
                                  "is-invalid": errors?.short_description,
                                })}
                                {...register("short_description", {
                                  required: "Short Description is required",
                                })}
                              />
                            </InputGroup>
                            {errors.short_description && (
                              <span className="text-danger">
                                {errors.short_description.message}
                              </span>
                            )}
                          </Form.Group>
                        </Row>
                      </div>
                    </Col>

                    <Col md={3}>
                      <div className="main-form-section mt-3">
                        <Form.Label>image</Form.Label>

                        <Form.Group>
                          <Form.Control
                            className={classNames("", {
                              "is-invalid": errors?.image,
                            })}
                            type="file"
                            {...register("image", {
                              // required: "images is required",
                            })}
                            accept="image/*"
                          />
                        </Form.Group>
                        {errors.image && (
                          <span className="text-danger">
                            {errors.image.message}
                          </span>
                        )}
                      </div>
                    </Col>

                    <Col lg={3}>
                      <div className="main-form-section mt-3">
                        <Form.Label>Image Preview</Form.Label>
                        {imagePreview && (
                          <div className="image-preview-container">
                            <img
                              src={imagePreview}
                              alt="Category Preview"
                              className="image-preview"
                              style={{ width: "150px", height: "130px" }}
                            />
                          </div>
                        )}
                        {/* {typeof getValues("image") == "string" ? (
                          <div className="image-preview-container">
                            <img
                              src={IMG_URL + getValues("image")}
                              alt="Preview"
                              className="image-preview"
                              style={{ width: "150px", height: "130px" }}
                            />
                          </div>
                        ) : (
                          imageFile &&
                          imageFile?.length > 0 && (
                            <div className="image-preview-container">
                              <img
                                // src={URL.createObjectURL(getValues("image")[0])}
                                src={URL?.createObjectURL(imageFile[0])}
                                alt="Preview"
                                className="image-preview"
                                style={{ width: "100px", height: "100px" }}
                              />
                            </div>
                          )
                        )} */}
                      </div>
                    </Col>

                    <div className="main-form-section mt-5"></div>
                    <Card.Title>Social Media Details</Card.Title>
                    <hr />

                    {fields.map((variant, index) => (
                      <div key={variant.id} className="main-form-section mt-3">
                        <Row>
                          <Col md={3}>
                            <div className="main-form-section mt-3">
                              <Row className="row justify-content-center mb-2">
                                <Form.Label>Social Media</Form.Label>
                                <Controller
                                  name={`dignitaries_social_media.${index}.social_media_id`}
                                  {...register(
                                    `dignitaries_social_media.${index}.social_media_id`,
                                    {
                                      required: "Select Social Media",
                                    }
                                  )}
                                  control={control}
                                  render={({ field }) => (
                                    <Select
                                      styles={{
                                        control: (baseStyles) => ({
                                          ...baseStyles,
                                          borderColor: errors
                                            ?.dignitaries_social_media?.[index]
                                            ?.social_media_id
                                            ? "red"
                                            : baseStyles,
                                        }),
                                      }}
                                      {...field}
                                      options={social_media}
                                      onChange={(selectedOption) => {
                                        field.onChange(selectedOption.value); // Update Controller's value

                                        setValue(
                                          `dignitaries_social_media.${index}.social_media_id`,
                                          selectedOption
                                        );
                                      }}
                                    />
                                  )}
                                />
                                {errors?.dignitaries_social_media?.[index]
                                  ?.social_media_id && (
                                  <span className="text-danger">
                                    {
                                      errors?.dignitaries_social_media?.[index]
                                        ?.social_media_id.message
                                    }
                                  </span>
                                )}
                              </Row>
                            </div>
                          </Col>

                          {/* Price */}
                          <Col md={3}>
                            <div className="main-form-section mt-3">
                              <Row className="justify-content-center">
                                <Form.Label>Link</Form.Label>
                                <Form.Group>
                                  <InputGroup>
                                    <Form.Control
                                      type="text"
                                      name={`dignitaries_social_media.${index}.link`}
                                      placeholder="link"
                                      className={classNames("", {
                                        "is-invalid":
                                          errors?.dignitaries_social_media?.[
                                            index
                                          ]?.link, // Updated error handling
                                      })}
                                      {...register(
                                        `dignitaries_social_media.${index}.link`,
                                        {
                                          required: "link is required",
                                        }
                                      )}
                                    />
                                  </InputGroup>
                                  {errors?.dignitaries_social_media?.[index]
                                    ?.link && (
                                    <span className="text-danger">
                                      {
                                        errors.dignitaries_social_media[index]
                                          .link.message
                                      }
                                    </span>
                                  )}
                                </Form.Group>
                              </Row>
                            </div>
                          </Col>

                          {/* <Col lg={6}>
                              <div className="main-form-section mt-3">
                                <Form.Label>images</Form.Label>

                                <Form.Group>
                                  <Form.Control
                                    className={classNames("", {
                                      "is-invalid": errors?.image,
                                    })}
                                    type="file"
                                    multiple
                                    {...register(`dignitaries_social_media.${index}.image`, {
                                      required: "images is required",
                                    })}
                                    id={`variantImages${index}`}
                                    accept="image/*"
                                  />
                                </Form.Group>
                                {errors.dignitaries_social_media?.[index]?.image && (
                                  <span className="text-danger">
                                    {errors.dignitaries_social_media?.[index]?.image.message}
                                  </span>
                                )}
                              </div>
                            </Col> */}
                          <Col lg={3}>
                            <button
                              className="mt-3 add-varient"
                              type="button"
                              onClick={() => remove(index)}
                            >
                              Remove
                            </button>
                          </Col>
                        </Row>
                      </div>
                    ))}

                    <div className="main-form-section mt-3"></div>
                    <hr />
                    <div className="text-center">
                      <button
                        type="button"
                        className="add-varient"
                        onClick={() => append({})}
                      >
                        + Add
                      </button>
                    </div>
                    <Row className="mt-5 pb-3">
                      <div className="d-flex justify-content-center">
                        <Link>
                          <CancelButton
                            name={"cancel"}
                            handleClose={props.handleClose}
                          />
                        </Link>

                        <Button
                          name={"save"}
                          onClick={handleSubmit(onSubmit)}
                          type="button"
                          className="save-btnnnnnn"
                        >
                          <FontAwesomeIcon
                            icon="fa-solid fa-floppy-disk"
                            className="me-2"
                          />
                          save
                        </Button>
                      </div>
                    </Row>
                  </Row>
                </Form>
              </Container>
            </Card.Body>
          </Card>
        </Offcanvas.Body>
      </Offcanvas>

      <ModalSave
        message={showModal.message}
        showErrorModal={showModal.code ? true : false}
      />
    </>
  );
};

export default EditOffCanvance;
