import React, { useContext } from "react";
import { useState, useEffect } from "react";
import "../../Tabels/Tabels.css";
import { Link } from "react-router-dom";

import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";

import Offcanvas from "react-bootstrap/Offcanvas";
import Card from "react-bootstrap/Card";
import ModalSave from "../../common/ModelSave";
import Select from "react-select";
import { CancelButton, SaveButton } from "../../common/Button";
import { Row, Col, Form, Modal, Container, InputGroup, Button } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import classNames from "classnames";
import { Context } from "../../../utils/context";
import JoditEditor from "jodit-react";
import Table from "react-bootstrap/Table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
library.add(fas);

const EditOffCanvance = (props) => {
  const { postData, getData, Select2Data, IMG_URL, getDimension, deleteData } = useContext(Context);

  const id = props.show;
  const [showModal, setShowModal] = useState({
    code: 0,
    message: "",
  });

  const [selectedType, setSelectedType] = useState("");

  const handleTypeChange = (event) => {
    setSelectedType(event.target.value);
  };

  const {
    control,
    register,
    handleSubmit,
    getValues,
    setError,
    formState: { errors },
    watch,
    setValue,
    reset,
  } = useForm();
  const imageFile = watch("image");
  const imageFile2 = watch("mobile_image");

  const onSubmit = async (data) => {
    try {

      if(data?.s_date > data?.e_date){
        setError('s_date',{message: "Start Date cannot be future date of end date"})
        return;
      }
      const DataToSend = new FormData();

      DataToSend.append("name", data?.name);
      DataToSend.append("code", data?.code);
      DataToSend.append("type", data?.type);
      if(data?.type === "percentage"){
        DataToSend.append("discount", data?.discount);
      }else{
        DataToSend.append("total_amount", data?.discount);
      }
      
      DataToSend.append("coupon_message", data?.coupon_message);
      DataToSend.append("coupon_info", data?.coupon_info);
      DataToSend.append("minimum_order", data?.minimum_order);
      DataToSend.append("use_per_cupon", data?.use_per_cupon);
      DataToSend.append("use_per_customer", data?.use_per_customer);
      DataToSend.append("s_date", data?.s_date);
      DataToSend.append("e_date", data?.e_date);

      DataToSend.append(`image`, data?.image[0]);
      DataToSend.append(`mobile_image`, data?.mobile_image[0]);

      const response = await postData(`/masters/coupon-codes/${id}`, DataToSend);
      console.log("response", response);
      console.log("data to send", DataToSend);
      if (response?.success) {
        await setShowModal({ code: response?.code, message: response?.message });
      } else {
        await setShowModal({ code: response?.code, message: response?.message });
      }
      setTimeout(() => {
        setShowModal(0);
        props.handleClose();
      }, 1000);
    } catch (error) {
      console.log(error);
    }
  };




  console.log(getValues('images[0]'), 'llll');

  const GetEditData = async () => {
    const response = await getData(`/masters/coupon-codes/${id}`);
    // setData(response);
    // setData(response?.data);
    reset(response?.data);
    setSelectedType(response?.data?.type);
    if(response?.data?.type === 'percentage'){
      setValue('discount',response?.data?.discount);
    }else{
      setValue('discount',response?.data?.total_amount);
    }
    setValue('s_date', response?.data?.s_date?.split('T')[0] || '');
    setValue('e_date', response?.data?.e_date?.split('T')[0] || '');
    
  };


  const handleStartDateChange =async (e) => {
    await setValue('s_date', e.target.value);
    // Assuming you want to clear the end date when start date changes
    await setValue('e_date', '');
  };
  useEffect(() => {
    GetEditData();
  }, []);


  return (
    <>
      <Offcanvas
        show={props.show}
        style={{ width: "80%" }}
        placement={"end"}
        onHide={props.handleClose}
      >
        <Offcanvas.Header closeButton>
          {/* <Offcanvas.Title>Edit Employee</Offcanvas.Title> */}
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Card className="shadow-lg p-3 mb-5 bg-white rounded">
            <Card className="shadow-lg p-3 mb-5 bg-white rounded">
              <Card.Body>
                <Card.Title>Edit Coupon Code</Card.Title>
                <hr />
                <Container>
                  <Form
                    // onSubmit={() => handleSubmit(onSubmit)}
                    role="form"
                  // className="stateclass"
                  >
                    <Row>

                      <Col md={4}>
                        <div className="main-form-section mt-3">
                          <Row className="justify-content-center">
                            <Form.Label>Type</Form.Label>
                            <Form.Group>
                              <InputGroup>
                                <Form.Control
                                  as="select"
                                  name="type"
                                  className={classNames("", {
                                    "is-invalid": errors?.type,
                                  })}
                                  {...register("type", {
                                    required: "Type is required",
                                  })}
                                  onChange={handleTypeChange}
                                >
                                  <option value="">Select Type</option>
                                  <option value="percentage">Percentage</option>
                                  <option value="fixedamount" >Fixed Amount</option>
                                  {/* <option value="combo">Combo</option> */}
                                </Form.Control>
                              </InputGroup>
                              {errors.type && (
                                <span className="text-danger">
                                  {errors.type.message}
                                </span>
                              )}
                            </Form.Group>
                          </Row>
                        </div>
                      </Col>

                      <Col md={4}>
                        <div className="main-form-section mt-3">
                          <Row className="justify-content-center">
                            <Form.Label>Name</Form.Label>
                            <Form.Group>
                              <InputGroup>
                                <Form.Control
                                  type="text"
                                  name="name"
                                  placeholder="Name .."
                                  className={classNames("", {
                                    "is-invalid": errors?.name,
                                  })}
                                  {...register("name", {
                                    required: "Name is required",
                                  })}
                                />
                              </InputGroup>
                              {errors.name && (
                                <span className="text-danger">
                                  {errors.name.message}
                                </span>
                              )}
                            </Form.Group>
                          </Row>
                        </div>
                      </Col>

                      <Col md={4}>
                        <div className="main-form-section mt-3">
                          <Row className="justify-content-center">
                            <Form.Label>Coupon Code </Form.Label>
                            <Form.Group>
                              <InputGroup>
                                <Form.Control
                                  type="text"
                                  name="code"
                                  placeholder="Coupon Code .."
                                  className={classNames("", {
                                    "is-invalid": errors?.code,
                                  })}
                                  {...register("code", {
                                    required: "Coupon Code is required",
                                  })}
                                />
                              </InputGroup>
                              {errors.code && (
                                <span className="text-danger">
                                  {errors.code.message}
                                </span>
                              )}
                            </Form.Group>
                          </Row>
                        </div>
                      </Col>

                      <Col md={4}>
                        <div className="main-form-section mt-3">
                          <Row className="justify-content-center">

                            <Form.Label>
                              {selectedType === "" ? "Discount" : selectedType}
                            </Form.Label>

                            <Form.Group>
                              <InputGroup>
                                <Form.Control
                                  type="number"
                                  name="discount"
                                  placeholder={selectedType === "" ? "Discount" : selectedType}
                                  max={100}
                                  min={0}
                                  step="0.01"
                                  className={classNames("", {
                                    "is-invalid": errors?.discount,
                                  })}
                                  {...register("discount", {
                                    required: "Discount is required",
                                    validate: (value) => {
                                      if (!value) return "Discount is required";
                                      if (selectedType === "percentage") {
                                        // Validate percentage discount
                                        if (parseFloat(value) < 1 || parseFloat(value) > 100) {
                                          return "Discount must be between 1% and 100%";
                                        }
                                      } else {
                                        const minimumOrderValue = parseFloat(getValues("minimum_order"));
                                        if (parseFloat(value) <= 0) {
                                          return "Discount must be a positive number";
                                        }

                                        // Check if discount is less than minimum order value
                                        if (parseFloat(value) >= minimumOrderValue) {
                                          return "Discount must be less than the minimum order value";
                                        }
                                      }
                                      return true; // Return true for valid input
                                    },
                                  })}
                                />
                              </InputGroup>
                              {errors.discount && (
                                <span className="text-danger">
                                  {errors.discount.message}
                                </span>
                              )}
                            </Form.Group>
                          </Row>
                        </div>
                      </Col>

                      {/* <Col md={4}>
                      <div className="main-form-section mt-3">
                        <Row className="justify-content-center">
                          <Form.Label>Total Ammount</Form.Label>
                          <Form.Group>
                            <InputGroup>
                              <Form.Control
                                type="number"
                                name="total_amount"
                                placeholder="Total Ammount"
                                className={classNames("", {
                                  "is-invalid": errors?.total_amount,
                                })}
                                {...register("total_amount", {
                                  required: "Total Ammount is required",
                                })}
                              />
                            </InputGroup>
                            {errors.total_amount && (
                              <span className="text-danger">
                                {errors.total_amount.message}
                              </span>
                            )}
                          </Form.Group>
                        </Row>
                      </div>
                    </Col> */}


                      <Col md={4}>
                        <div className="main-form-section mt-3">
                          <Row className="justify-content-center">
                            <Form.Label>Coupon Message</Form.Label>
                            <Form.Group>
                              <InputGroup>
                                <Form.Control
                                  type="text"
                                  name="coupon_message"
                                  placeholder="Coupon Message"
                                  className={classNames("", {
                                    "is-invalid": errors?.coupon_message,
                                  })}
                                  {...register("coupon_message", {
                                    required: "Coupon Message is required",
                                  })}
                                />
                              </InputGroup>
                              {errors.coupon_message && (
                                <span className="text-danger">
                                  {errors.coupon_message.message}
                                </span>
                              )}
                            </Form.Group>
                          </Row>
                        </div>
                      </Col>

                      <Col md={4}>
                      <div className="main-form-section mt-3">
                        <Row className="justify-content-center">
                          <Form.Label>Start Date</Form.Label>
                          <Form.Group>
                            <InputGroup>
                              <Form.Control
                                type="date"
                                name="s_date"
                                placeholder="Start Date"
                                className={classNames("", {
                                  "is-invalid": errors?.s_date,
                                })}
                                min={new Date().toISOString()?.split('T')?.[0]}
                                onChange={handleStartDateChange}
                                {...register("s_date", {
                                  required: "Start Date is required",
                                  validate: {
                                    notPast: (value) => {
                                      const today = new Date().toISOString().split('T')[0]; // Get today's date in YYYY-MM-DD format
                                      return value >= today || "Start Date cannot be in the past";
                                    }
                                  }
                                })}
                              />
                            </InputGroup>
                            {errors.s_date && (
                              <span className="text-danger">
                                {errors.s_date.message}
                              </span>
                            )}
                          </Form.Group>
                        </Row>
                      </div>
                    </Col>
                    <Col md={4}>
                      <div className="main-form-section mt-3">
                        <Row className="justify-content-center">
                          <Form.Label>End Date</Form.Label>
                          <Form.Group>
                            <InputGroup>
                              <Form.Control
                                type="date"
                                name="e_date"
                                placeholder="End Date"
                                className={classNames("", {
                                  "is-invalid": errors?.e_date,
                                })}
                                {...register("e_date", {
                                  required: "End Date is required",
                                  validate: {
                                    notPast: (value) => {
                                      const startDate = new Date().toISOString().split('T')[0]; // Start date in YYYY-MM-DD format
                                      return value >= startDate || "End Date cannot be in the past of Start Date";
                                    }
                                  }
                                })}
                              />
                            </InputGroup>
                            {errors.e_date && (
                              <span className="text-danger">
                                {errors.e_date.message}
                              </span>
                            )}
                          </Form.Group>
                        </Row>
                      </div>
                    </Col>

                      <Col md={4}>
                        <div className="main-form-section mt-3">
                          <Row className="justify-content-center">
                            <Form.Label>Minimum Order Amount</Form.Label>
                            <Form.Group>
                              <InputGroup>
                                <Form.Control
                                  type="number"
                                  name="minimum_order"
                                  placeholder="Minimum Order"
                                  className={classNames("", {
                                    "is-invalid": errors?.minimum_order,
                                  })}
                                  {...register("minimum_order", {
                                    required: "Minimum Order is required",
                                  })}
                                />
                              </InputGroup>
                              {errors.minimum_order && (
                                <span className="text-danger">
                                  {errors.minimum_order.message}
                                </span>
                              )}
                            </Form.Group>
                          </Row>
                        </div>
                      </Col>

                      <Col md={4}>
                        <div className="main-form-section mt-3">
                          <Row className="justify-content-center">
                            <Form.Label>Use Per Coupon</Form.Label>
                            <Form.Group>
                              <InputGroup>
                                <Form.Control
                                  type="number"
                                  name="use_per_cupon"
                                  placeholder="Use Per Coupon"
                                  className={classNames("", {
                                    "is-invalid": errors?.use_per_cupon,
                                  })}
                                  {...register("use_per_cupon", {
                                    required: "Use Per Coupon is required",
                                  })}
                                />
                              </InputGroup>
                              {errors.use_per_cupon && (
                                <span className="text-danger">
                                  {errors.use_per_cupon.message}
                                </span>
                              )}
                            </Form.Group>
                          </Row>
                        </div>
                      </Col>

                      <Col md={4}>
                        <div className="main-form-section mt-3">
                          <Row className="justify-content-center">
                            <Form.Label>Use Per Customer</Form.Label>
                            <Form.Group>
                              <InputGroup>
                                <Form.Control
                                  type="number"
                                  name="use_per_customer"
                                  placeholder="Use Per Customer"
                                  className={classNames("", {
                                    "is-invalid": errors?.use_per_customer,
                                  })}
                                  {...register("use_per_customer", {
                                    required: "Use Per Customer is required",
                                    validate: {
                                      lessThanOrEqual: (value) => parseInt(value) <= parseInt(getValues("use_per_cupon")) || "Use Per Customer must be less than or equal to Use Per Coupon"
                                    }
                                  })}
                                />
                              </InputGroup>
                              {errors.use_per_customer && (
                                <span className="text-danger">
                                  {errors.use_per_customer.message}
                                </span>
                              )}
                            </Form.Group>
                          </Row>
                        </div>
                      </Col>
                      <Col lg={4}>
                        <div className="main-form-section mt-3">

                          <Form.Label >
                            Image
                          </Form.Label>

                          <Form.Group>
                            <Form.Control
                              className={classNames("", {
                                "is-invalid": errors?.image,
                              })}
                              type="file"
                              {...register("image", {
                                // required: "Image is required",

                              })}
                              accept=".jpg, .jpeg, .png"
                            />
                          </Form.Group>
                          {errors.image && (
                            <span className="text-danger">
                              {errors.image.message}
                            </span>
                          )}


                        </div>
                      </Col>

                      <Col lg={4}>
                        <div className="main-form-section mt-3">

                          <Form.Label >
                            Mobile Image
                          </Form.Label>

                          <Form.Group>
                            <Form.Control
                              className={classNames("", {
                                "is-invalid": errors?.mobile_image,
                              })}
                              type="file"
                              {...register("mobile_image", {
                                // required: "Mobile Image is required",

                              })}
                              accept=".jpg, .jpeg, .png"
                            />
                          </Form.Group>
                          {errors.mobile_image && (
                            <span className="text-danger">
                              {errors.mobile_image.message}
                            </span>
                          )}


                        </div>
                      </Col>



                      <Col lg={4}>
                      </Col>

                      <Col lg={4}>
                        <div className="main-form-section mt-3">

                          <Form.Label >
                            Image Preview
                          </Form.Label>

                          {typeof getValues("image") == "string" ? (
                            <div className="image-preview-container">
                              <img
                                src={IMG_URL + getValues("image")}
                                alt="Preview"
                                className="image-preview"
                                style={{ width: "150px", height: "130px" }}
                              />
                            </div>
                          ) : (
                            imageFile &&
                            imageFile?.length > 0 && (
                              <div className="image-preview-container">
                                <img
                                  // src={URL.createObjectURL(getValues("image")[0])}
                                  src={URL?.createObjectURL(imageFile[0])}
                                  alt="Preview"
                                  className="image-preview"
                                  style={{ width: "100px", height: "100px" }}
                                />
                              </div>
                            )
                          )}

                        </div>
                      </Col>



                      <Col lg={4}>
                        <div className="main-form-section mt-3">

                          <Form.Label >
                            Mobile Image Preview
                          </Form.Label>

                          {typeof getValues("mobile_image") == "string" ? (
                            <div className="image-preview-container">
                              <img
                                src={IMG_URL + getValues("mobile_image")}
                                alt="Preview"
                                className="image-preview"
                                style={{ width: "150px", height: "130px" }}
                              />
                            </div>
                          ) : (
                            imageFile2 &&
                            imageFile2?.length > 0 && (
                              <div className="image-preview-container">
                                <img
                                  // src={URL.createObjectURL(getValues("image")[0])}
                                  src={URL?.createObjectURL(imageFile2[0])}
                                  alt="Preview"
                                  className="image-preview"
                                  style={{ width: "100px", height: "100px" }}
                                />
                              </div>
                            )
                          )}

                        </div>
                      </Col>

                      <Row className="mt-5 pb-3">
                        <div className="d-flex justify-content-center">
                          <Link>
                            <CancelButton
                              name={"cancel"}
                              handleClose={props.handleClose}
                            />
                          </Link>

                          <Button
                            name={"save"}
                            onClick={handleSubmit(onSubmit)}
                            type="button"
                          >
                            save
                          </Button>
                        </div>
                      </Row>
                    </Row>


                  </Form>
                </Container>
              </Card.Body>
            </Card>
          </Card>
        </Offcanvas.Body>
      </Offcanvas >

      <ModalSave
        message={showModal.message}
        showErrorModal={showModal.code ? true : false}
      />
    </>
  );
};

export default EditOffCanvance;
